var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "rft-bid-specs-tab"
    }
  }, [_c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [!_vm.isBuyer ? _c('div', {
    staticClass: "clearfix flex justify-content-end",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_vm.isEditingMode ? _c('el-button-group', [_c('el-button', {
    directives: [{
      name: "debouncing",
      rawName: "v-debouncing",
      value: 1000,
      expression: "1000"
    }],
    attrs: {
      "type": "primary",
      "icon": "el-icon-check"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.handleSaveSpecChanges('form');
      }
    }
  }, [_vm._v(" Save changes ")]), _vm.isEditingMode ? _c('el-button', {
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.resetEdit('form');
      }
    }
  }, [_vm._v(" Reset ")]) : _vm._e(), _c('el-button', {
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.cancelEdit('form');
      }
    }
  }, [_vm._v(" Cancel")])], 1) : _c('el-button', {
    on: {
      "click": _vm.editMode
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "pencil",
      "size": 20
    }
  })], 1)], 1) : _vm._e(), _c('el-skeleton', {
    attrs: {
      "rows": 10,
      "animated": "",
      "loading": _vm.isProductSpecLoading
    }
  }, [_c('el-collapse', {
    attrs: {
      "accordion": ""
    },
    model: {
      value: _vm.activeCategories,
      callback: function ($$v) {
        _vm.activeCategories = $$v;
      },
      expression: "activeCategories"
    }
  }, [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.form
    }
  }, _vm._l(_vm.form.productSpec, function (category, index) {
    return _c('el-collapse-item', {
      key: index,
      attrs: {
        "title": category.categoryName,
        "name": category.id
      }
    }, [_c('template', {
      slot: "title"
    }, [_vm._v(" " + _vm._s(category.categoryName) + " "), category.questions.some(function (item) {
      return item.mandatory === 1;
    }) ? _c('el-tag', {
      staticClass: "ml-4",
      attrs: {
        "type": _vm.mandatoryAnsweredQuestionCount(category) === category.questions.filter(function (item) {
          return item.mandatory === 1;
        }).length ? 'success' : 'info'
      }
    }, [_vm._v(" " + _vm._s(_vm.mandatoryAnsweredQuestionCount(category)) + " of " + _vm._s(category.questions.filter(function (item) {
      return item.mandatory === 1;
    }).length) + " mandatory question(s) answered ")]) : _vm._e(), _c('el-tag', {
      staticClass: "ml-2",
      attrs: {
        "type": "info"
      }
    }, [_vm._v(" " + _vm._s(category.questions.length) + " questions ")])], 1), _c('el-row', {
      attrs: {
        "gutter": 24
      }
    }, _vm._l(category.questions, function (question, idx) {
      return _c('el-col', {
        key: idx,
        attrs: {
          "span": 12,
          "xs": 24,
          "sm": 24,
          "md": 12
        }
      }, [_c('el-form-item', {
        attrs: {
          "label": question.name,
          "rules": [{
            required: question.mandatory === 1,
            validator: _vm.validateQuestions,
            trigger: ['blur', 'change']
          }],
          "prop": ("productSpec." + index + ".questions." + idx + ".answer")
        }
      }, [_c('el-input', {
        attrs: {
          "readonly": !_vm.isEditingMode,
          "placeholder": question === null || question === void 0 ? void 0 : question.description
        },
        model: {
          value: question.answer,
          callback: function ($$v) {
            _vm.$set(question, "answer", $$v);
          },
          expression: "question.answer"
        }
      }), question.default_answer && question.default_answer !== question.answer && _vm.isEditingMode ? _c('small', [_vm._v(" Default answer: "), _c('el-link', {
        staticStyle: {
          "font-size": "0.8rem"
        },
        attrs: {
          "type": "primary"
        },
        on: {
          "click": function ($event) {
            question.answer = question.default_answer;
          }
        }
      }, [_vm._v(" " + _vm._s(question.default_answer) + " ")])], 1) : _vm._e()], 1)], 1);
    }), 1)], 2);
  }), 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }